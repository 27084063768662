import Vue from 'vue'
import { Row, Col, Menu, MenuItem, MenuItemGroup, Breadcrumb, BreadcrumbItem } from 'element-ui'
import App from './App.vue'
import router from '@/router/router.js'
//引入store
import store from './store'
//  import "amfe-flexible";
// import 'lib-flexible/flexible.js'
Vue.config.productionTip = false
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Row)
Vue.use(Col)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)


new Vue({
    render: h => h(App),
    router,
    beforeCreate() {
        Vue.prototype.$bus = this //安装全局事件总线，$bus就是当前应用的vm
    },
    store
}).$mount('#app')

router.afterEach(() => {
    window.scrollTo(0, 0);
})


// function refreshRem(){
//   //获取屏幕宽度
//   var width = docEl.getBoundingClientRect().width;
//   if (width / dpr < 700) {
//   宽度小于700
//   width = 540 * dpr;
//   }
//   var rem = width / 10;
//   docEl.style.fontSize = rem + 'px';
//   flexible.rem = win.rem = rem;
//   }