<template>
  <div>
    <PaiHeader />
    <!-- <PaiSwiper /> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <PaiFooter />
  </div>
</template>

<script>
import PaiHeader from "@/components/PaiHeader";
import PaiFooter from "@/components/PaiFooter";

export default {
  name: "Layout",
  components: {
    PaiHeader,
    PaiFooter,
  },
};
</script>

<style>
</style>