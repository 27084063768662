//该文件用于创建Vuex中最为核心的store
import { nanoid } from "nanoid";
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//应用Vuex插件
Vue.use(Vuex)

//准备actions——用于响应组件中的动作
const actions = {

    }
    //准备mutations——用于操作数据（state）
const mutations = {

}

//准备state——用于存储数据
const state = {
        productList: [{
                id: nanoid(),
                title: "慢跑鞋",
                nick: 'JoggingShoes',
                goods: [{
                        id: nanoid(),
                        title: "无伤慢跑鞋白色 1.0",
                        mainImg: require("@/assets/uploads/wushangbai.png"),
                    },
                    {
                        id: nanoid(),
                        title: "无伤慢跑鞋黑色 1.0",
                        mainImg: require("@/assets/uploads/wushanghei.png"),
                    },

                ],
            },
            {
                id: nanoid(),
                title: "竞速鞋",
                nick: 'RacingShoes',
                goods: [{
                        id: nanoid(),
                        title: "100Pro3.0白色竞速鞋",
                        mainImg: require("@/assets/uploads/3.0white.png"),
                    }, {
                        id: nanoid(),
                        title: "100Pro3.0橙色竞速鞋",
                        mainImg: require("@/assets/uploads/3.0orange.jpg"),
                    }, {
                        id: nanoid(),
                        title: "100Pro2.5白色竞速鞋",
                        mainImg: require("@/assets/uploads/2.5white.png"),
                    }, {
                        id: nanoid(),
                        title: "100Pro2.5橙色竞速鞋",
                        mainImg: require("@/assets/uploads/2.5orange.png"),
                    },
                    {
                        id: nanoid(),
                        title: "100Pro2.0白色竞速鞋",
                        mainImg: require("@/assets/uploads/Racing-shoes-white.png"),
                    },
                    {
                        id: nanoid(),
                        title: "100Pro2.0橙色竞速鞋",
                        mainImg: require("@/assets/uploads/Racing-shoes-orange.png"),
                    },
                ],
            },
            {
                id: nanoid(),
                title: "训练鞋",
                nick: 'TrainShoes',
                goods: [{
                        id: nanoid(),
                        title: "2.0白色训练鞋",
                        mainImg: require("@/assets/uploads/2.0xunlian-white.png"),
                    },
                    {
                        id: nanoid(),
                        title: "2.0橙色训练鞋",
                        mainImg: require("@/assets/uploads/2.0xunlian-orange.png"),
                    },
                ],
            },
            {
                id: nanoid(),
                title: "服饰",
                nick: 'Clothes',
                goods: [{
                        id: nanoid(),
                        title: "白色运动背心",
                        mainImg: require("@/assets/uploads/white-shirt.png"),
                    },
                    {
                        id: nanoid(),
                        title: "紫色运动背心",
                        mainImg: require("@/assets/uploads/purple-shirt.png"),
                    },
                    {
                        id: nanoid(),
                        title: "运动短裤",
                        mainImg: require("@/assets/uploads/Shorts.png"),
                    },
                    {
                        id: nanoid(),
                        title: "状元短袖",
                        mainImg: require("@/assets/uploads/T-shirt.png"),
                    },
                    {
                        id: nanoid(),
                        title: "运动套装",
                        mainImg: require("@/assets/uploads/Suit-blue.png"),
                    },
                    {
                        id: nanoid(),
                        title: "运动套装",
                        mainImg: require("@/assets/uploads/Suit-green.png"),
                    },
                    {
                        id: nanoid(),
                        title: "加绒卫衣",
                        mainImg: require("@/assets/uploads/Hoodie.png"),
                    },
                    {
                        id: nanoid(),
                        title: "加绒卫衣",
                        mainImg: require("@/assets/uploads/Hoodie2.png"),
                    },
                    {
                        id: nanoid(),
                        title: "圆领卫衣",
                        mainImg: require("@/assets/uploads/Hoodie3.png"),
                    },
                    {
                        id: nanoid(),
                        title: "运动外套",
                        mainImg: require("@/assets/uploads/Coat.png"),
                    },
                    {
                        id: nanoid(),
                        title: "风衣外套",
                        mainImg: require("@/assets/uploads/Coat2.png"),
                    },
                    {
                        id: nanoid(),
                        title: "运动紧身裤",
                        mainImg: require("@/assets/uploads/Pants.png"),
                    },
                ],
            },
            {
                id: nanoid(),
                title: "配件",
                nick: 'Parts',
                goods: [{
                        id: nanoid(),
                        title: "状元背包",
                        mainImg: require("@/assets/uploads/Backpack.png"),
                    },
                    {
                        id: nanoid(),
                        title: "液体镁粉",
                        mainImg: require("@/assets/uploads/Mg-powder.png"),
                    },
                ],
            },
            {
                id: nanoid(),
                title: "拖鞋",
                nick: 'Slipper',
                goods: [{
                        id: nanoid(),
                        title: "运动拖鞋白色",
                        mainImg: require("@/assets/uploads/tuoxie1.png"),
                    },
                    {
                        id: nanoid(),
                        title: "运动拖鞋黑色",
                        mainImg: require("@/assets/uploads/tuoxie2.png"),
                    },
                    {
                        id: nanoid(),
                        title: "运动拖鞋卡其色",
                        mainImg: require("@/assets/uploads/tuoxie3.png"),
                    },

                ],
            },

        ],
        activeIndex: 0
    }
    // router-link 跳转之后到达页面的顶部
    //创建并暴露store
export default new Vuex.Store({
    actions,
    mutations,
    state,
})