<template>
  <!-- 头部 -->
  <header class="header mg">
    <!-- logo -->
    <div class="logo">
      <h1>
        <a href="index.html" title="派燃烧"> 派燃烧官网-为竞速而生！
        </a>
      </h1>
      <div class="info">派燃烧</div>
    </div>
    <!-- 导航区域 -->
    <ul class="nav">
      <li>
        <!-- <a href="./index.html">首页</a> -->
        <router-link to="/index">首页</router-link>
      </li>
      <li>
        <router-link to="/about-us">关于我们</router-link>
        <div class="selected_link">
          <router-link to="/about-us/company-profile">企业简介</router-link>
          <router-link to="/about-us/development-history">品牌发展</router-link>
          <!-- <router-link to="/about-us/check">防伪查询</router-link> -->
        </div>
      </li>
      <li>
        <router-link to="/product-center"
          >产品中心</router-link
        >
        <div class="selected_link">
          <router-link 
          v-for="p in $store.state.productList" 
          :to="{
            path: `/product-center/${p.nick}`
          }"
          
          :key="p.id"
            >
            {{p.title}}
          
          </router-link>
          
          
          <!-- <router-link to="/product-center/TrainShoes"
            >训练鞋</router-link
          ><router-link to="/product-center/Clothes"
            >服饰</router-link
          ><router-link to="/product-center/Parts"
            >配件</router-link
          > -->
        </div>
      </li>
      <li>
        <router-link to="/training-plan">训练计划</router-link>
      </li>
      <li>
        <router-link to="/public-welfare">派燃烧公益</router-link>
      </li>
      <li>
        <router-link to="/contact-us">联系我们</router-link>
      </li>
    </ul>
    <!-- 商城链接 -->
    <!-- <ul class="mall">
      <li>
        <a target="_blank" href="https://pairanshao.tmall.com/index.htm?spm=a1z10.1-b.w5002-24329467719.2.62eb32b3Hu8j4z">
          <img src="@/assets/images/tmall.png" alt="" />
        </a>
      </li>
      <li>
        <a target="_blank" href="https://app.kwaixiaodian.com/merchant/shop/detail?id=3993559125357">
          <img src="@/assets/images/ks.png" alt="" />
        </a>
      </li>
      <li>
        <a target="_blank" href="https://haohuo.jinritemai.com/views/product/detail?id=3499385283688974185&origin_type=604">
          <img src="@/assets/images/dy.png" alt="" />
        </a>
      </li>
      <li>
        <a target="_blank"  href="https://mobile.yangkeduo.com/goods.html?goods_id=327048078689">
          <img src="@/assets/images/pdd.png" alt="" />
        </a>
      </li>
    </ul> -->
  </header>
</template>

<script scoped>
export default {
  name: "PaiHeader",
  methods: {

  },
  watch: {
    $route(to) {
      if(to.name === "RacingShoes") {
        this.$store.state.activeIndex=this.$store.state.productList[0].id
      }
      if(to.name === "TrainShoes") {
        this.$store.state.activeIndex=this.$store.state.productList[1].id
      }
      if(to.name === "Clothes") {
        this.$store.state.activeIndex=this.$store.state.productList[2].id
      }
      if(to.name === "Parts") {
        this.$store.state.activeIndex=this.$store.state.productList[3].id
      }
    }
  }
};
</script>

<style>


.logo {
  display: flex;
  align-items: center;
  width: 200px;
  height: 100%;
  /* background-color: #2fc389; */
}

.logo h1 {
  width: 100px;
}
.logo h1 a  {
  display: block;
  width: 100px;
  height: 100px;
  background-image: url(../assets/images/logopai.png) ;
  background-repeat: no-repeat;
  background-position:center;
  text-indent: -9999px;
  overflow: hidden;
}
.logo h1 img {
  width: 100%;
}

.logo .info {
  font-size: 30px;
  font-weight: 700;
  color: rgb(4, 4, 5);
  text-shadow: 1px 1px 2px #000;
}

.nav {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav li {
  position: relative;
  height: 100%;
}
.nav li > a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 30px 0 30px;
  color: #000;
}

.selected_link {
  display: none;
  position: absolute;
  z-index: 555;
  width: 100%;
  background-color: #fff;
}

.selected_link a {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #000;
}

.nav li a:hover {
  background-color: orange;
  color: #fff;
}

.nav li:hover .selected_link {
  display: block;
  /* z-index: 10; */
}
.mall {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 260px;
  height: 100%;
  /* background-color: pink; */
}

.mall li {
  width: 50px;
  height: 50px;
  /* background-color: yellow; */
}

.mall li img {
  width: 100%;
}

/* 头部结束 */

.details {
  display: flex;
  flex-wrap: wrap;
  /* height: 468px; */
  margin-bottom: 50px;
}
.details li {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  height: 290px;
  justify-content: space-around;
  padding: 10px 30px 30px 30px;
  transform: translateX(20px);
  border-bottom: 2px solid #ccc;
}
.details img {
  width: 100%;
}
</style>