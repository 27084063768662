<template>
<div class="kezi">
  <footer class="footer"  >
    
      <div class="mg">
      <div   class = "mall">
        <h3>
          店铺直达
        </h3>
        <ul class="mall_1">
          <li>
            <a target="_blank" href="https://pairanshao.tmall.com/index.htm?spm=a1z10.1-b.w5002-24329467719.2.62eb32b3Hu8j4z">
              <img src="@/assets/images/tmall.jpg" alt="" />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://app.kwaixiaodian.com/merchant/shop/list?id=1266878357">
              <img src="@/assets/images/ks.jpg" alt="" />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://v.douyin.com/6G28Lbe">
              <img src="@/assets/images/dy.jpg" alt="" />
            </a>
          </li>
          <li>
            <a target="_blank"  href="https://mobile.yangkeduo.com/mall_page.html?mall_id=933659567&msn=hvnj3elzw7g32xlvlb7a5zku2a_axbuy&has_decoration=1&_x_out_of_station=offical_account">
              <img src="@/assets/images/pdd.jpg" alt="" />
            </a>
          </li>
          <li>
            <a target="_blank"  href="http://mall.jd.com/index-12241767.html"> 
              <img src="@/assets/images/jd.png" alt="" />
            </a>
          </li>
          <li>
            <a target="_blank"  href="https://shop144073068.taobao.com/">
              <img src="@/assets/images/tb.png" alt="" />
            </a>
          </li>
        </ul>
        <h3 class="left1">
          关注我们
        </h3>
        <ul class="mall_1">
          
          <li>
            <a target="_blank" href="https://v.douyin.com/jXaufFV/">
              <img src="@/assets/images/dy1.png" alt=""/>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://weibo.com/u/6259671400">
              <img src="@/assets/images/paiweibologo.png" alt=""/>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://mp.weixin.qq.com/s/YtlRmYNpbboQlxhtjserJA">
              <img src="@/assets/images/paiweixinlogo.png" alt=""/>
            </a>
          </li>
          <!-- <li>
            快手
            <a target="_blank" href="https://mp.weixin.qq.com/s/YtlRmYNpbboQlxhtjserJA">
              <img src="@/assets/images/kuaishou.png" alt=""/>
            </a>
          </li> -->
          <li>
            <!-- 小红书 -->
            <a target="_blank" href="https://www.xiaohongshu.com/user/profile/5fbb63bc0000000001001115?xhsshare=CopyLink&appuid=5fbb63bc0000000001001115&apptime=1664329418">
              <img src="@/assets/images/xiaohongshu.png" alt=""/>
            </a>
          </li>
          <li>
            <!-- 哔哩哔哩 -->
            <a target="_blank" href=" https://b23.tv/MYl0gnV">
              <img src="@/assets/images/bilibili.png" alt=""/>
            </a>
          </li>
          <!-- <li>
            腾讯视频
            <a target="_blank" href="https://mp.weixin.qq.com/s/YtlRmYNpbboQlxhtjserJA">
              <img src="@/assets/images/tencent.png" alt=""/>
            </a>
          </li> -->
        </ul>
      </div>
      <div class="list">
        <dl>
          <dt>产品系列</dt>
          <dd>
            <router-link to="/product-center/RacingShoes"
              >竞速鞋</router-link
            >
          </dd>
          <dd>
            <router-link to="/product-center/TrainShoes"
              >训练鞋</router-link
            >
          </dd>
          <dd>
            <router-link to="/product-center/Clothes"
              >服饰</router-link
            >
          </dd>
          <dd>
            <router-link to="/product-center/Parts"
              >配件</router-link
            >
          </dd>
        </dl>
        <dl>
          <dt>关于我们</dt>
          <dd>
            <router-link to="/about-us/company-profile">企业简介</router-link>
          </dd>
          <dd>
            <router-link to="/about-us/development-history"
              >品牌发展</router-link
            >
          </dd>
          <!-- <dd><router-link to="/about-us/CategoryRD">品类研发</router-link></dd> -->
        </dl>
        <dl>
          <dt>快速链接</dt>
          <dd>
            <a target="_blank"
              href="https://pairanshao.tmall.com/index.htm?spm=a1z10.1-b.w5002-24329467719.2.62eb32b3Hu8j4z"
              >派燃烧商城</a
            >
          </dd>
          <!-- <dd>
            <a href="#">跑友会</a>
          </dd> -->
        </dl>
        <dl>
          <dt>联系我们</dt>
          <dd>电话：02986558468</dd>
          <dd>邮箱：597618977@qq.com</dd>
          <dd>地址：陕西省西安市经济技术开发区未央路168号西安城市运动公园内阳光岛服务中心二楼</dd>
        </dl>
        <!-- <dl>
          <dt>关注我们</dt>
          <dd>
            <div class="pai-tab-list">
              <div class="wx-logo"></div>
              <div class="wb-logo"></div>
            </div>
            <div class="pai-tab-content">
              <div>
                <img src="@/assets/shop/wx.jpg" alt="" />
              </div>
              <div>
                <img src="@/assets/shop/wb.jpg" alt="" />
              </div>
            </div>
          </dd>
        </dl> -->
        <!-- <dl>
                    <dt>防伪查询</dt>
                    <dd>
                        <input type="text"> <br>
                        <button>查询</button>
                    </dd>
                </dl> -->
      </div>
      <div class="copyright">
        <div class="left">© 2019 - 2023 All rights reserved LOOP-BURN Corp.</div>
        <div class="right">
          <a href="#">法律声明</a>
          <a href="#">隐私权政策</a>
          <a href="#">网站导航</a>
          <a target="_blank" href="http://beian.miit.gov.cn"><span>陕ICP备2022007147号-1</span></a>
          <br />
  
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61011202000511" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
              <img src="http://www.beian.gov.cn/img/ghs.png" style="float:left;"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">陕公网安备 61011202000511号</p>
          </a>
          
        </div>
      </div>
      </div>
    

  </footer>
  </div>
</template>

<script>
// import func from 'vue-editor-bridge';
export default {
  name: "PaiFooter",
  // mounted() {
  //   const titles = document.querySelector(".pai-tab-list").children;
  //   console.log(titles);
  //   const items = document.querySelector('.pai-tab-content').children
  //   console.log(items);
  //   titles[0].addEventListener('hover',() => {
  //     this.className = 'wx-logo-actived'
  //   })
  // },
};
</script>

<style scoped>

.kezi{
  width: 100%;
  background-color: #000;
}
.footer {
  width: 1200px;
  height: 400px;
  margin:0 auto;
  background-color: #000;
  color: #bbb;
  font-size: 13px;

  
}  
.footer a {
  color: #bbb;
}
.footer .list {
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: flex-start;
  padding-top: 30px;
  border-bottom: 2px solid #bbb;
}
.footer .list dl{
  margin: 0 100px 0 0;
}
.footer .list dt {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}
.footer .list dd {
  padding-top: 15px;
}

.footer .copyright {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.footer .copyright a {
  padding: 0 10px 0 10px;
}
.footer .copyright a:last-child {
  display: flex;
  padding-top: 5px;
}
.mall{
  display: flex;
  justify-content: space-between;
}
.mall_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 460px;
  height: 100%;
  margin: 0 25px;
  /* background-color: pink; */
}

.mall_1 li {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  /* background-color: yellow; */
}

.mall_1 li img {
  width: 100%;
}
.mall h3 {
  
  font-size: 16px;
  font-weight: 700;
  color: #fff;
} 
/* 底部结束 */
.mall .left1 {
  margin-left:75px;
}
.pai-tab-list {
  height: 30px;
}
.pai-tab-list > div {
  width: 150px;
  height: 30px;
  /* background-color: pink; */
  float: left;
  background-size: contain;
}
.wx-logo {
  background: url(../assets/images/paiweixinlogo.png) no-repeat;
}
.wb-logo {
  background: url(../assets/images/paiweibologo.png) no-repeat;
}
.wx-logo-actived {
  background: url(../assets/images/paiweixinlogoselected.png);
}

.wb-logo-actived {
  background: url(../assets/images/paiweibologoselected.png);
}
.pai-tab-content > div {
  float: left;
  width: 150px;
  height: 150px;
  padding: 5px;
}
.qrCodebg {
  background-color: rgb(180, 180, 181);
}
.pai-tab-content > div img {
  width: 100%;
}
</style>