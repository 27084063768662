<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
import "@/assets/css/common.css"

import Layout from "@/pages/Layout.vue"
// import "@/assets/css/product-center.css"
// import "@/assets/css/about-us.css"


export default {
  name: 'App',
  components: {
    Layout
  }
}
</script>

<style>
</style>
