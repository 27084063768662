import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
    routes: [{
            path: '/',
            redirect: '/index'
        },
        {
            path: '/index',
            component: () =>
                import ('@/components/PaiSwiper')
        },
        {
            path: '/about-us',
            component: () =>
                import ('@/pages/AboutUs'),
            redirect: '/about-us/company-profile',
            children: [{
                    path: 'company-profile',
                    component: () =>
                        import ('@/pages/AboutUs/CompanyProfile/index.vue')
                },
                {
                    path: 'development-history',
                    component: () =>
                        import ('@/pages/AboutUs/DevelopmentHistory')
                },
                {
                    path: 'check',
                    component: () =>
                        import ('@/pages/AboutUs/check')
                },
            ]
        },
        {
            path: '/product-center',
            component: () =>
                import ('@/pages/ProductCenter'),
            redirect: '/product-center/RacingShoes',
            children: [{
                    name: 'JoggingShoes',
                    path: 'JoggingShoes',

                    component: () =>
                        import ('@/pages/ProductCenter/ProductList/JoggingShoes')
                },
                {
                    name: 'RacingShoes',
                    path: 'RacingShoes',

                    component: () =>
                        import ('@/pages/ProductCenter/ProductList/RacingShoes')
                },
                {
                    name: 'TrainShoes',
                    path: 'TrainShoes',
                    component: () =>
                        import ('@/pages/ProductCenter/ProductList/TrainShoes')
                },
                {
                    name: 'Clothes',
                    path: 'Clothes',
                    component: () =>
                        import ('@/pages/ProductCenter/ProductList/Clothes')
                },
                {
                    name: 'Parts',
                    path: 'Parts',
                    component: () =>
                        import ('@/pages/ProductCenter/ProductList/Parts')
                },
                {
                    name: 'Slipper',
                    path: 'Slipper',
                    component: () =>
                        import ('@/pages/ProductCenter/ProductList/Slipper')
                },
            ]
        },
        {
            //训练计划
            path: '/training-plan',
            component: () =>
                import ('@/pages/TrainingPlan'),


        },
        {
            path: '/public-welfare',
            component: () =>
                import ('@/pages/PublicWelfare'),
        },
        {
            path: '/contact-us',
            component: () =>
                import ('@/pages/ContactUs'),
        }
    ]
})